import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { Layout } from "src/components/layout"

const ContactContainer = styled.p `
  display: block;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0;

  @media (min-width: 1024px) {
    width: 640px;
    margin: 2.5rem 0;
  }
`

const ContactTitle = styled.h1 `
  margin-top: 0rem;
  margin-bottom: 0;
  color: #000;
  font-size: 2.5rem;
  line-height: 1.25;
  font-weight: 300;
`

const ContactP = styled.p `
  margin: 1rem 0;
  font-size: 1.25rem;
  font-family: "EB Garamond";

  & > a {
    color: #C22700;
    text-decoration: none;
    padding-bottom: 0.125rem;
    border-bottom: 1px solid #C22700;
  }
`

const ContactForm = styled.form `
  width: 550px;
  margin-top: 2.5rem;
  display: none;
`

const NameField = styled.div `
  & > label {
    display: none;
  }

  & > input {
    border-radius: 0.25rem;
    width: 100%;
    padding: 0.5rem;
    margin: 1rem 0 0;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1) inset;
    border: 1px solid #ccc;
  }
`

const EmailField = styled.div `
  & > label {
    display: none;
  }

  & > input {
    border-radius: 0.25rem;
    width: 100%;
    padding: 0.5rem;
    margin: 1rem 0 0;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1) inset;
    border: 1px solid #ccc;
  }
`

const MessageField = styled.div `
  & > label {
    display: none;
  }

  & > textarea {
    border-radius: 0.25rem;
    width: 100%;
    padding: 0.5rem;
    margin: 1rem 0 0;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1) inset;
    border: 1px solid #ccc;
  }
`

const SubmitButton = styled.input `
  margin-top: 1rem;
  border-radius: 2rem;
  background: #2384FF;
  color: #fff;
  font-weight: 700;
  padding: 0.5rem 1.5rem;
  border: 0 none;
`

export default ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Contact | {data.site.siteMetadata.title}</title>
      </Helmet>
      <ContactContainer>
        <ContactTitle>Contact</ContactTitle>
        <ContactP>Have a question for me? Feedback on my writing or the website? Send me <a href="mailto:cristina@cristinahartmann.com">an email</a>.</ContactP>
        <ContactForm name="contact" method="post" data-netlify="true">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <NameField>
            <label htmlFor="name">Name</label>
            <input type="text" name="name" id="name" placeholder="Your name" />
          </NameField>
          <EmailField>
            <label htmlFor="email">Email</label>
            <input type="text" name="email" id="email" placeholder="Your email" />
          </EmailField>
          <MessageField>
            <label htmlFor="message">Message</label>
            <textarea name="textarea" id="message" rows="6" placeholder="Message" />
          </MessageField>
          <SubmitButton type="submit" value="Send" />
        </ContactForm>
      </ContactContainer>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`